import React, { useState, useEffect } from "react";
import MloCard from '../components/MloCard.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

var result = []
function Team() {
  const [LoData, setLoData] = useState([])
  var links = [
    { endpoint: 'https://d16w4zbj1jaawn.cloudfront.net/mortgagepossible.com/includes/lodata.json'},
    { endpoint: 'https://d16w4zbj1jaawn.cloudfront.net/swmcretail.com/includes/lodata.json'},
    { endpoint: 'https://d16w4zbj1jaawn.cloudfront.net/lowrates.com/includes/lodata.json'},
    { endpoint: 'https://d16w4zbj1jaawn.cloudfront.net/sunwestmortgage.com/includes/lodata.json'},
    { endpoint: 'https://d16w4zbj1jaawn.cloudfront.net/lowratespr.com/includes/lodata.json'}
  ]

  useEffect(() => {
    links.forEach(function (link) {
      fetch(link.endpoint)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          // Process the image URL
          const updatedData = data.map(item => {
            // Replace the base URL in the image path
            item.imgUrl = item.imgUrl.replace('https://swmc-landingpages.s3.amazonaws.com/', 'https://d16w4zbj1jaawn.cloudfront.net/');
            return item;
          });
          
          result = result.concat(updatedData);
          setLoData(result);
        })
        .catch(function (error) {
          console.error("Error:", error);
        });
    });
  }, []);

  const loComponents = LoData.map(lodata => <MloCard key={lodata.loID} lodata={lodata} />)
  
  return (
    <div id="team-wrapper">
      <Container className="mt-5">
        <Row>
          <Col lg={12} className="text-center">
            <br />
            <h1>Our Team Of Experts</h1>
            <p>We invite you to meet our team of experts and navigate through them to learn more.</p>
          </Col>
          <Container>
            <Row>
              {loComponents}
            </Row>
          </Container>
          <Col className="text-center m-4 pt-3">
            <Image fluid src="./assest/NewSunWestLogo-1.png" alt="NewSunWestLogo-1" style={{ width: "200px" }} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Team;
