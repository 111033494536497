import React, { useCallback, useState } from "react";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";
import "./GenerateLead.css";

export const GenerateLead = (props) => {
  const [node, setNode] = useState(null);

  // useCallback triggers when thankYouRef.current changes.
  // tracks changes to formRef and stores current with setNode
  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  return (
    <div id="generateLead" className={props.className}>
      {node && props.title !== '' && (
        <h2 className="formHeader">
          {props.title ? props.title : 'Please fill the information below to continue'}
        </h2>
      )}
      <div className="formBody">
        <BorrowerLeadGenerationTool
          statesDomain={props.isSWMC ? "ny.swmc.com" : "swmcretail.com"}
          statesCode="SUNWST000"
          branch={"058"}
          leadSource={props.isSWMC ? "NET001" : "swmcretail.com"}
          loanOfficerUid={"CSTC"}
          loanPurpose={props.loanPurpose}
          offerLoanType={props.offerLoanType}
          ref={formRef}
        />
      </div>
    </div>
  );
};

export default GenerateLead;
