import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Sitemap() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Site Map | Sun West</title>
        <meta
          name="description"
          content="Sun West sitemap"
        />
      </Helmet>
      <Container className="mt-5">
        <Row>
          <Col xl={12} className="py-4">
            <h3 className="fl-heading">
              <span className="fl-heading-text">Sitemap</span>
            </h3>
          </Col>
          <Col lg={3}>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                About Us
                <ul>
                  <li>
                    <Link to="../our-story">Our Story</Link>
                  </li>
                  <li>
                    <Link to="../our-mortgage-process">Our Mortgage Process</Link>
                  </li>
                  <li>
                    <Link to="../leadership">Leadership</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/careers"
                      target="_blank"
                      rel="noopener"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col lg={3}>
            Loan Services
            <ul>
              <li>
                <Link to="../conventional-loans">Conventional Loans</Link>
              </li>
              <li>
                <Link to="../fha-loans">FHA Loans</Link>
              </li>
              <li>
                <Link to="../investment-property-loans">
                  Investment Property Loans
                </Link>
              </li>
              <li>
                <Link to="../jumbo-loans">Jumbo Loans</Link>
              </li>
              <li>
                <Link to="../refinancing">Refinancing</Link>
              </li>
              <li>
                <Link to="../renovation-construction-loans">
                  Renovation/Construction Loans
                </Link>
              </li>
              <li>
                <Link to="../reverse-mortgage-hecm">Reverse Mortgage/HECM</Link>
              </li>
              <li>
                <a href="https://www.swmc.com/va-loans">VA Loans</a>
              </li>
            </ul>
          </Col>
          <Col lg={3}>
            Resources
            <ul>
              <li>
                Get Started
                <ul>
                  <li>
                    <Link to="../home-buyers-guide">Home Buyer&#39;s Guide</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/angelai/calculators"
                      target="_blank"
                      rel="noopener"
                    >
                      Calculators
                    </a>
                  </li>
                  <li>
                    <Link to="../refinancing-guide">Refinance Guide</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col lg={3}>
            Contact
            <ul>
              <li>
                <Link to="../contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="../faq">Faq</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
